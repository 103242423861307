import { computed, defineComponent, onMounted, reactive } from "vue";
const Sortable = require("sortablejs");
export default defineComponent({
    emits: ["onEnd"],
    props: {
        selector: {
            type: String,
            required: false,
        },
        sotrIcon: {
            type: Boolean,
            require: false,
        },
        datas: {
            type: Array,
            required: true,
        },
        columns: {
            type: Array,
            required: false,
        },
    },
    setup(props, { emit }) {
        onMounted(() => {
            const tbodys = document.querySelector(props.selector || ' .sort-table .el-table__body tbody');
            Sortable.Sortable.create(tbodys, {
                onEnd(args) {
                    emit("onEnd", args);
                },
                handle: props.sotrIcon ? ".sort-icon" : "",
            });
        });
        const sortDatas = reactive(props.datas);
        const slotList = computed(() => {
            return ((props.columns &&
                props.columns.filter((item) => item.slot && item.slot.length > 0)) ||
                []);
        });
        return {
            sortDatas,
            slotList,
        };
    },
});
